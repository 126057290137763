<template>
  <modal
    :name="modalName"
    :width="800"
    height="auto"
    :adaptive="true"
    :scrollable="true"
    @before-open="beforeOpen"
    class="applicant--info-modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        <div>
          <!--          <span v-if="beforeAccepted">{{ $t('booking.modal.acceptSellBooking.title') }}</span>-->
          <!--          <span v-else>{{ $t('booking.modal.applicantInfo.title') }}</span>-->
          {{ $t('booking.modal.applicantInfo.title') }}
        </div>
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          <!--          <span v-if="beforeAccepted">{{ $t('booking.modal.acceptSellBooking.title') }}</span>-->
          <!--          <span v-else>{{ $t('booking.modal.applicantInfo.title') }}</span>-->
          {{ $t('booking.modal.applicantInfo.title') }}
        </div>
        <!--        <div v-if="beforeAccepted">-->
        <!--          <div class="form&#45;&#45;group mb-4" :class="{ 'has-error': validation.hasError('finalPrice') }">-->
        <!--            <div class="left&#45;&#45;col">-->
        <!--              <label for="final-price">-->
        <!--                <span>{{ $t('booking.modal.acceptSellBooking.finalPrice') }}</span>-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="right&#45;&#45;col">-->
        <!--              <div class="input&#45;&#45;group">-->
        <!--                <div class="append&#45;&#45;left">-->
        <!--                  S$-->
        <!--                </div>-->
        <!--                <cleave-->
        <!--                  class="basic&#45;&#45;input price&#45;&#45;input"-->
        <!--                  type="text"-->
        <!--                  id="final-price"-->
        <!--                  name="newPrice"-->
        <!--                  v-model="finalPrice"-->
        <!--                  :options="cleaveOption"-->
        <!--                  ref="price"-->
        <!--                />-->
        <!--              </div>-->
        <!--              <div class="val-error" v-if="validation.hasError('finalPrice')">-->
        <!--                {{ validation.firstError('finalPrice') }}-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="modal--content mb-4" v-if="modalData">
          <div class="mb-4">
            <div class="subtitle--text">
              {{ $t('booking.modal.applicantInfo.subtitle') }}
            </div>
            <hr />
            <div class="form--group mb-2">
              <div class="left--col">
                <label>{{ $t('profile.name') }}</label>
              </div>
              <div class="right--col">
                {{ modalData.name }}
              </div>
            </div>
            <div class="form--group mb-2" v-if="modalData.phone">
              <div class="left--col">
                <label>{{ $t('profile.phone') }}</label>
              </div>
              <div class="right--col">
                {{ modalData.phone }}
              </div>
            </div>
            <div class="form--group mb-2" v-if="modalData.address">
              <div class="left--col">
                <label>{{ $t('profile.address2') }}</label>
              </div>
              <div class="right--col">
                {{ modalData.address }}
              </div>
            </div>
            <div class="subtitle--text mt-4">
              {{ $t('profile.ktp') }}
            </div>
            <hr />
            <div class="form--group mb-2">
              <div class="left--col">
                <label>{{ $t('profile.ktp') }}</label>
              </div>
              <div class="right--col">
                {{ modalData.identity_card_number }}
              </div>
            </div>
            <div class="id--card mt-2" v-if="ktpPhoto">
              <img :src="`data:${ktpPhoto.mime_type};base64, ${ktpPhoto.data}`" />
            </div>
            <div class="subtitle--text mt-4">
              {{ $t('general.offerLetter') }}
            </div>
            <hr />
            <div class="form--group mb-2">
              <div class="left--col">
                <label>{{ $t('general.offerLetter') }}</label>
              </div>
              <div class="right--col">
                <a
                  class="btn btn-primary--outline main--btn mt-0"
                  :href="modalData.offer_letter_file_url"
                  target="_blank"
                >
                  <img src="@/assets/img/icons/document.svg" class="mr-2 mb-0" />
                  <span>{{ $t('general.seeOfferLetter') }}</span>
                </a>
              </div>
            </div>
          </div>
          <!--          <div>-->
          <!--            <div class="subtitle&#45;&#45;text">-->
          <!--              {{ $t('profile.npwp') }}-->
          <!--            </div>-->
          <!--            <hr />-->
          <!--            <div class="form&#45;&#45;group mb-2">-->
          <!--              <div class="left&#45;&#45;col">-->
          <!--                <label>{{ $t('profile.npwp') }}</label>-->
          <!--              </div>-->
          <!--              <div class="right&#45;&#45;col">-->
          <!--                {{ modalData.npwp }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="id&#45;&#45;card mt-2" v-if="npwpPhoto">-->
          <!--              <img :src="`data:${npwpPhoto.mime_type};base64, ${npwpPhoto.data}`" />-->
          <!--            </div>-->
          <!--            <div class="subtitle&#45;&#45;text" v-if="modalData.regional_data">-->
          <!--              {{ $t('booking.modal.applicantInfo.regionalInfo') }}-->
          <!--            </div>-->
          <!--            <hr v-if="modalData.regional_data" />-->
          <!--            <div class="form&#45;&#45;group mb-2" v-if="modalData.regional_data">-->
          <!--              <div class="left&#45;&#45;col">-->
          <!--                <label>{{ $t('profile.province') }}</label>-->
          <!--              </div>-->
          <!--              <div class="right&#45;&#45;col">-->
          <!--                {{ modalData.regional_data.province_name }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="form&#45;&#45;group mb-2" v-if="modalData.regional_data">-->
          <!--              <div class="left&#45;&#45;col">-->
          <!--                <label>{{ $t('profile.city') }}</label>-->
          <!--              </div>-->
          <!--              <div class="right&#45;&#45;col">-->
          <!--                {{ modalData.regional_data.city_name }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="form&#45;&#45;group mb-2" v-if="modalData.regional_data">-->
          <!--              <div class="left&#45;&#45;col">-->
          <!--                <label>{{ $t('profile.district') }}</label>-->
          <!--              </div>-->
          <!--              <div class="right&#45;&#45;col">-->
          <!--                {{ modalData.regional_data.district_name }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="form&#45;&#45;group mb-2" v-if="modalData.regional_data">-->
          <!--              <div class="left&#45;&#45;col">-->
          <!--                <label>{{ $t('profile.village') }}</label>-->
          <!--              </div>-->
          <!--              <div class="right&#45;&#45;col">-->
          <!--                {{ modalData.regional_data.village_name }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div class="row justify-content-center align-items-center" v-if="beforeAccepted">
          <div class="col-12 col-md-6">
            <button
              class="btn btn-primary--outline btn-block"
              type="button"
              @click="close(modalName)"
            >
              {{ $t('general.btn.back') }}
            </button>
          </div>
          <div class="col-12 col-md-6">
            <button class="btn btn-primary btn-block" @click="submit">
              {{ $t('booking.modal.acceptSellBooking.acceptSign') }}
            </button>
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
// import Cleave from 'vue-cleave-component';
import { Validator } from 'simple-vue-validator';
const SectionLoader = () => import('@/components/content-loading/section-loading');
export default {
  name: 'applicant-info-modal',
  components: {
    // Cleave,
    SectionLoader,
  },
  data: () => ({
    modalName: 'sell-applicant-info',
    message: '',
    loading: false,
    beforeAccepted: true,
    applicationUuid: null,
    actionType: null,
    modalData: null,
    ktpPhoto: null,
    npwpPhoto: null,
    finalPrice: null,
    cleaveOption: {
      prefix: '',
      numeral: true,
      numeralPositiveOnly: true,
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralIntegerScale: 16,
      numeralDecimalScale: 0,
    },
  }),
  computed: {},
  validators: {
    finalPrice(value) {
      return Validator.value(value).required(this.$i18n.t('errors.sellPrice.null'));
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      this.beforeAccepted = event.params.beforeAccepted;
      this.applicationUuid = event.params.uuid;
      this.actionType = event.params.actionType;
      try {
        let response = await this.$http.get(
          '/api/v2/sell_transaction/get_billing_info?uuid=' + this.applicationUuid,
        );
        this.modalData = response.data.data;
        if (event.params.initFinalPrice) {
          this.finalPrice = event.params.initFinalPrice;
        }
        response = await this.$http.get(
          '/api/v2/sell_transaction/get_billing_info_photo?uuid=' + this.applicationUuid,
        );
        this.ktpPhoto = response.data.data.identity_card_number_photo;
        this.npwpPhoto = response.data.data.npwp_photo;
      } catch (e) {
        console.log('Error loading billing info: ', e);
      }
    },
    async submit() {
      let resultCheck = true;
      const isValid = await this.$validate();
      if (isValid) {
        if (!this.checkBankAccountInbox) {
          resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
        }
        if (resultCheck === false) {
          this.$modal.show('modal-input-bank-account');
        } else {
          this.$modal.show('sell-digital-agreement-modal', {
            uuid: this.applicationUuid,
            actionType: this.actionType,
          });
          this.close();

          // this.$router.push({
          //   path: `/booking/sign-otp/${this.applicationUuid}`,
          //   query: { final_price: this.finalPrice, action_name: 'OWNER_SIGN_OTP' },
          // });

          // this.$swal({
          //   title: this.$i18n.t('booking.swal.acceptSell.title'),
          //   text: this.$i18n.t('booking.swal.acceptSell.message'),
          //   type: 'warning',
          //   showCancelButton: true,
          //   confirmButtonText: this.$i18n.t('general.yes'),
          //   cancelButtonText: this.$i18n.t('general.no'),
          //   allowOutsideClick: false,
          // }).then(async result => {
          //   if (result.value) {
          //     try {
          //       this.loading = true;
          //       const postAcceptResult = await this.$store.dispatch('sellTransaction/ownerAccept', {
          //         uuid: this.applicationUuid,
          //         finalPrice: this.finalPrice,
          //       });
          //       console.log('Post Accept Result: ', postAcceptResult);
          //       this.$emit('actionDone');
          //       this.close();
          //     } catch (e) {
          //       console.log('Error accepting sell booking: ', e);
          //     } finally {
          //       this.loading = false;
          //     }
          //   }
          // });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}
</style>
